<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";


/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Nazox"
        },
        {
          text: "Dashboard",
          active: true
        }
      ]
    };
  },
  mounted() {
      this.title= this.$t('dashboard.text')
      this.items=[
        {
          text: this.$t('dashboard.text'),
          href: "/"
        }
      ]
      
    //   this.page.title=this.$t('menuitems.devices.lists')
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      
    </div>
  </Layout>
</template>